import Wrapper from "../components/mics/Wrapper";
import Profitxl from "../pages/profitxl";
import AddVerticle from "../pages/verticles/add";
import Verticle from "../pages/verticles";
import Projects from "../pages/projects";
import AddProject from "../pages/projects/add";
import Vert from "../pages/vert";
import SearchZip from "../pages/searchzip";
import ProjectDetails from "../pages/projects/details";
import JornayaLeads from "../pages/jornayaLeads/jornayaLeads";
import AddMedicare from "../pages/medicare/medicare";
import Services from "../pages/service/service";
import MedicareLeads from "../pages/medicareLeads/medicareLeads";
import AddWindow from "../pages/window/window";
import WindowLeads from "../pages/window/windowLeads";
import AddRoofing from "../pages/roofing/roofing";
import RoofingLeads from "../pages/roofing/roofingLeads";
import WindowLeadsDetail from "../pages/window/windowLeadsDetail";
import RoofingLeadsDetail from "../pages/roofing/roofingDetail";
import InqPosting from "../pages/inqPosting/inqPosting";
import InqLeads from "../pages/inqPosting/inqLeads";
import InqLeadsDetail from "../pages/inqPosting/inqLeadDetails";
import KitchenLeadsDetail from "../pages/kitchen/kitchenDetail";
import KitchenLeads from "../pages/kitchen/kitchenLeads";
import AddKitchen from "../pages/kitchen/kitchen";
import AddFlooring from "../pages/flooring/flooring";
import FlooringLeadsDetail from "../pages/flooring/flooringDetail";
import FlooringLeads from "../pages/flooring/flooringLeads";
import AddPlumbing from "../pages/plumbing/plumbing";
import PlumbingLeadsDetail from "../pages/plumbing/plumbingDetail";
import PlumbingLeads from "../pages/plumbing/plumbingLeads";
import AddBathroom from "../pages/bathroom/bathroom";
import BathroomLeadsDetail from "../pages/bathroom/bathroomDetail";
import BathroomLeads from "../pages/bathroom/bathroomLeads";
import AddSiding from "../pages/siding/siding";
import SidingLeadsDetail from "../pages/siding/sidingDetail";
import SidingLeads from "../pages/siding/sidingLeads";
import AddSolar from "../pages/solar/solar";
import SolarLeadsDetail from "../pages/solar/solarDetail";
import SolarLeads from "../pages/solar/solarLeads";
import AddWalkInTubs from "../pages/walkInTubs/walkInTubs";
import WalkInTubsLeadsDetail from "../pages/walkInTubs/walkInTubsDetail";
import WalkInTubsLeads from "../pages/walkInTubs/walkInTubsLeads";
import AddHVAC from "../pages/hvac/hvac";
import HVACLeads from "../pages/hvac/hvacLeads";
import HVACLeadsDetail from "../pages/hvac/hvacDetail";
import AddGutter from "../pages/gutter/gutter";
import GutterLeadsDetail from "../pages/gutter/gutterDetail";
import GutterLeads from "../pages/gutter/gutterLeads";
import AddWaterDamage from "../pages/waterDamage/waterDamage";
import WaterDamageLeads from "../pages/waterDamage/waterDamageLeads";
import WaterDamageLeadsDetail from "../pages/waterDamage/waterDamageDetail";
import AddMedicare2 from "../pages/medicare2/medicare2";
import MedicareLeads2 from "../pages/medicareLeads2/medicareLeads";
import BuyerData from "../pages/buyerData/buyer";
import AddSettellment from "../pages/settelment/settelment";
import SettelmentLeads from "../pages/settelment/settelmentLeads";
import AddHome from "../pages/homeWarranty/home";
import HomeLeads from "../pages/homeWarranty/homeLeads";
import HomeLeadsDetail from "../pages/homeWarranty/homeDetail";
import AddAuto from "../pages/auto/auto";
import AutoLeads from "../pages/auto/autoLeads";
import AutoLeadsDetail from "../pages/auto/autoDetail";
import AddHomeInc from "../pages/Home insurance/addHomeInc";
import SetellmentLeadsDetail from "../pages/settelment/settelmentDetail";
import AddMedicareQoutes from "../pages/medicareQoutes/medicareQoutes";
import MedicareQoutesLeads from "../pages/medicareQoutes/medicareQoutesLeads";
import AddRoofingOne from "../pages/roofing/roofingOne";
import { uid } from "uid";

const Routservices = [
  {
    id: uid(),
    route: "/profitxl",
    component: <Wrapper compnent={<Profitxl />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/verticle/add",
    component: <Wrapper compnent={<AddVerticle />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/verticle",
    component: <Wrapper compnent={<Verticle />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/project/add",
    component: <Wrapper compnent={<AddProject />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/project",
    component: <Wrapper compnent={<Projects />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/project/:id",
    component: <Wrapper compnent={<ProjectDetails />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/services",
    component: <Wrapper compnent={<Services />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/jornaya-leads",
    component: <Wrapper compnent={<JornayaLeads />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/medicare-leads",
    component: <Wrapper compnent={<MedicareLeads />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/medicare",
    component: <AddMedicare />,
    layout: true,
  },

  {
    id: uid(),
    route: "/window-leads",
    component: <Wrapper compnent={<WindowLeads />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/window-leads/:id",
    component: <Wrapper compnent={<WindowLeadsDetail />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/window",
    component: <AddWindow />,
    layout: true,
  },

  {
    id: uid(),
    route: "/vert",
    component: <Vert />,
    layout: true,
  },
  {
    id: uid(),
    route: "/",
    component: <SearchZip />,
    layout: true,
  },
  {
    id: uid(),
    route: "/roofing",
    component: <AddRoofing />,
    layout: true,
  },
  {
    id: uid(),
    route: "/roofing-leads",
    component: <Wrapper compnent={<RoofingLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/roofing-leads/:id",
    component: <Wrapper compnent={<RoofingLeadsDetail />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/inq-posting",
    component: <InqPosting />,
    layout: true,
  },
  {
    id: uid(),
    route: "/inq-leads",
    component: <Wrapper compnent={<InqLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/inq-leads/details/:id",
    component: <Wrapper compnent={<InqLeadsDetail />} />,
    layout: true,
  },

  // Kitchen...............................

  {
    id: uid(),
    route: "/kitchen",
    component: <AddKitchen />,
    layout: true,
  },

  {
    id: uid(),
    route: "/kitchen-leads",
    component: <Wrapper compnent={<KitchenLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/kitchen-leads/:id",
    component: <Wrapper compnent={<KitchenLeadsDetail />} />,
    layout: true,
  },

  // Flooring...............................

  {
    id: uid(),
    route: "/flooring",
    component: <AddFlooring />,
    layout: true,
  },

  {
    id: uid(),
    route: "/flooring-leads",
    component: <Wrapper compnent={<FlooringLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/flooring-leads/:id",
    component: <Wrapper compnent={<FlooringLeadsDetail />} />,
    layout: true,
  },

  // Plumbing...............................

  {
    id: uid(),
    route: "/plumbing",
    component: <AddPlumbing />,
    layout: true,
  },

  {
    id: uid(),
    route: "/plumbing-leads",
    component: <Wrapper compnent={<PlumbingLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/plumbing-leads/:id",
    component: <Wrapper compnent={<PlumbingLeadsDetail />} />,
    layout: true,
  },

  // Bathroom...............................

  {
    id: uid(),
    route: "/bathroom",
    component: <AddBathroom />,
    layout: true,
  },

  {
    id: uid(),
    route: "/bathroom-leads",
    component: <Wrapper compnent={<BathroomLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/bathroom-leads/:id",
    component: <Wrapper compnent={<BathroomLeadsDetail />} />,
    layout: true,
  },

  // Bathroom...............................

  {
    id: uid(),
    route: "/siding",
    component: <AddSiding />,
    layout: true,
  },

  {
    id: uid(),
    route: "/siding-leads",
    component: <Wrapper compnent={<SidingLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/siding-leads/:id",
    component: <Wrapper compnent={<SidingLeadsDetail />} />,
    layout: true,
  },

  // Solar...............................

  {
    id: uid(),
    route: "/solar",
    component: <AddSolar />,
    layout: true,
  },

  {
    id: uid(),
    route: "/solar-leads",
    component: <Wrapper compnent={<SolarLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/solar-leads/:id",
    component: <Wrapper compnent={<SolarLeadsDetail />} />,
    layout: true,
  },
  // home...............................

  {
    id: uid(),
    route: "/home",
    component: <AddHome />,
    layout: true,
  },

  {
    id: uid(),
    route: "/home-leads",
    component: <Wrapper compnent={<HomeLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/home-leads/:id",
    component: <Wrapper compnent={<HomeLeadsDetail />} />,
    layout: true,
  },

  // Walk in tubs...............................

  {
    id: uid(),
    route: "/walk-in-tubs",
    component: <AddWalkInTubs />,
    layout: true,
  },

  {
    id: uid(),
    route: "/walk-in-tubs-leads",
    component: <Wrapper compnent={<WalkInTubsLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/walk-in-tubs-leads/:id",
    component: <Wrapper compnent={<WalkInTubsLeadsDetail />} />,
    layout: true,
  },

  // HVAC...............................
  {
    id: uid(),
    route: "/hvac",
    component: <AddHVAC />,
    layout: true,
  },

  {
    id: uid(),
    route: "/hvac-leads",
    component: <Wrapper compnent={<HVACLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/hvac-leads/:id",
    component: <Wrapper compnent={<HVACLeadsDetail />} />,
    layout: true,
  },

  // Gutter...............................
  {
    id: uid(),
    route: "/gutter",
    component: <AddGutter />,
    layout: true,
  },

  {
    id: uid(),
    route: "/gutter-leads",
    component: <Wrapper compnent={<GutterLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/gutter-leads/:id",
    component: <Wrapper compnent={<GutterLeadsDetail />} />,
    layout: true,
  },

  // Watter Damage...............................
  {
    id: uid(),
    route: "/water-damage",
    component: <AddWaterDamage />,
    layout: true,
  },

  {
    id: uid(),
    route: "/water-damage-leads",
    component: <Wrapper compnent={<WaterDamageLeads />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/water-damage-leads/:id",
    component: <Wrapper compnent={<WaterDamageLeadsDetail />} />,
    layout: true,
  },
  {
    id: uid(),
    route: "/medicare-leads2",
    component: <Wrapper compnent={<MedicareLeads2 />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/medicare2",
    component: <AddMedicare2 />,
    layout: true,
  },
  {
    id: uid(),
    route: "/buyerLead",
    component: <Wrapper compnent={<BuyerData />} />,
    layout: true,
  },

  {
    id: uid(),
    route: "/settlement",
    component: <AddSettellment />,
    layout: true,
  },

  {
    id: uid(),
    route: "/auto",
    component: <AddAuto />,
    layout: true,
  },

  {
    id: uid(),
    route: "/auto-leads",
    component: <AutoLeads />,
    layout: true,
  },
  {
    id: uid(),
    route: "/auto-leads/:id",
    component: <AutoLeadsDetail />,
    layout: true,
  },
  {
    id: uid(),
    route: "/home-insurance",
    component: <AddHomeInc />,
    layout: true,
  },

  {
    id: uid(),
    route: "/auto-leads",
    component: <AutoLeads />,
    layout: true,
  },
  {
    id: uid(),
    route: "/auto-leads/:id",
    component: <AutoLeadsDetail />,
    layout: true,
  },
  {
    id: uid(),
    route: "/settlement-leads",
    component: <SettelmentLeads />,
    layout: true,
  },
  {
    id: uid(),
    route: "/settlement-leads/:id",
    component: <SetellmentLeadsDetail />,
    layout: true,
  },
  {
    id: uid(),
    route: "/medicare-Qoutes",
    component: <AddMedicareQoutes />,
    layout: true,
  },
  {
    id: uid(),
    route: "/medicare-Qoutes-leads",
    component: <MedicareQoutesLeads />,
    layout: true,
  },
  {
    id: uid(),
    route: "roofing-one",
    component: <AddRoofingOne />,
    layout: true,
  },
];
export default Routservices;
